import styled, { css } from "styled-components";

type TextAreaProps = {
  variantWidth: 'full' | 'small' | 'verySmall';
};

const InputBlockStyled = styled.div<TextAreaProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({variantWidth}) => {
    switch (variantWidth) {
      case 'full': return css`
        width: 100%;
      `

      case 'small': return css`
        width: 253px;
      `

      case 'verySmall': return css`
        width: 170px;
        height: 258px;
      `
    }
  }}
`;

type LabelProps = {
  isHelperText: boolean;
};

const LabelStyled = styled.label<LabelProps>`
  ${({isHelperText}) => isHelperText ? css`
    padding: 4px 0 7px 0;
  ` : css`
    padding: 4px 0 7px 16px;
  `}

  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  width: 100%;

  display: flex;
  gap: 10px;
`;

const TextAreaStyled = styled.textarea`
  resize: none;
  font-family: 'Roboto', sans-serif;
  color: #323232;
  height: 209px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #868687;
  border-radius: 10px;
  padding: 12px 16px;
  transition: border-color 0.5s;
  width: 100%;

  &:focus {
    padding: 11px 15px;
    border: 2px solid #4091F7;
    outline: none;
  }
`;

const ImgPlaceHolderStyled = styled.img`
  position: absolute;
  width: 18px;
  height: 18px;
  right: 15px;
  top: 47px;
`;

export {
  InputBlockStyled,
  LabelStyled,
  TextAreaStyled,
  ImgPlaceHolderStyled,
};