import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooksStore";
import { sendAppPWA, sendCloackSetting, updateAppPWA, updateCloackSettings } from "../../../../../api/requestService";
import { useEffect } from "react";
import { setIsCreateAvailable } from "../../../../../store/core/core";
import { BasicsEditType, CommentType, DescriptionsEditType, ReviewEditType } from "../../../../../types/EditsTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonItem from "../../../../../components/ButtonItem/ButtonItem";

const pathNames: { [key: string]: boolean } = {
  "/main/edit": false,
  "/main/create": true,
};

const ButtonCreate = () => {
  const navigate = useNavigate();
  const path = useLocation();

  const pathName = pathNames[path.pathname];

  const { category_idAll = [], trackersAll = [], developersAll = [], domainsAll = [], ratingsAll = [] } =
    useAppSelector((state) => state.itemsSelect) || {};
  const dispatch = useAppDispatch();
  const {basicsEdit, descriptionsEdit, settingsEdit, commentsEdit, id: idPWA, metricsEdit } = useAppSelector((state) => state.aplicationEdit);
  type CreateItems = BasicsEditType & DescriptionsEditType & {
    [key: string]: any;
  };

  const createCloackSetting = async () => {
    try {
      const idCloackSetting = await sendCloackSetting(settingsEdit);

      return idCloackSetting.id;
    } catch (error) {
      console.error("error to createCloack", error);
      throw new Error(`error to createCloack`);
    };
  };

  const createItems: CreateItems = { ...basicsEdit, ...descriptionsEdit};
  const { isCreateAvailable } = useAppSelector((state) => state.core);
  const css = isCreateAvailable
    ? `position: absolute; right: 5px; top: 10px; opacity: 1; pointer-events: all;`
    : ` position: absolute; right: 5px; top: 10px; opacity: 0.5; pointer-events: none;`;

  const filterIdItem = (toMapItem: any[], itemesToFind: string | number) => {
    return toMapItem.find((item) => item.name === itemesToFind)?.id;
  };

  const filterIdRating = (toMapItem: any[], itemesToFind: string | number) => {
    return toMapItem.find((item) => item.total_rating === itemesToFind)?.id;
  };

  const itemsToFetch = {
    internal_name: createItems.internal_name,
    name: createItems.name,
    category_id: filterIdItem(category_idAll, createItems.category_id),
    tracker_id: filterIdItem(trackersAll, createItems.tracker_id),
    developer_contacts_id: filterIdItem(developersAll, createItems.developer_contacts_id),
    total_downloads: Number(createItems.total_downloads),
    file_size: Number(createItems.file_size),
    min_load_time: Number(createItems.min_load_time),
    max_load_time: Number(createItems.max_load_time),
    rating_id: filterIdRating(ratingsAll, Number(createItems.rating_id)),
    update_date: createItems.update_date,
    description: createItems.description,
    update_description: createItems.update_description,
    domain_id: filterIdItem(domainsAll, createItems.domain_id),
    is_editors_choice: createItems.is_editors_choice,
    age_rating: createItems.age_rating,
    developer_name: createItems.developer_name,
    icon: createItems.icon[0],
    images: createItems.images,
    tags: createItems.tags.map(item => item.id),
    store_category_id: createItems.store_category_id.id,
    push_set_id: createItems.push_set_id.id,
    show_top_menu: createItems.show_top_menu,
    show_bottom_menu: createItems.show_bottom_menu,
    is_verified: createItems.is_verified,
  };

  useEffect(() => {
    const validateFields = () => {
      for (const key in createItems) {
        if (!createItems[key] && key !== 'is_editors_choice' && key !== 'show_top_menu' && key !== 'show_bottom_menu' && key !== 'is_verified') {
          return false;
        }
      }

      const isNotUseButton = !settingsEdit?.redirect_type
        // || commentsEdit.length === 0
        || (metricsEdit.facebookPixelId.length === 0 && metricsEdit.tikTokPixelId.length === 0);

      if (isNotUseButton) {
        return false;
      }

      return true;
    };

    if (validateFields()) {
      dispatch(setIsCreateAvailable(true));
    } else {
      dispatch(setIsCreateAvailable(false));
    }
  }, [basicsEdit, descriptionsEdit, settingsEdit, commentsEdit, metricsEdit]);

  const createApp = async (idCloackSetting: number) => {
    for (const key in itemsToFetch) {
      if (!createItems[key] && key !== 'is_editors_choice' && key !== 'show_top_menu' && key !== 'show_bottom_menu' && key !== 'is_verified') {
        throw new Error(`Missing value for ${key}`);
      }
    }

    const itemToCreate = {
      ...itemsToFetch,
      cloack_settings_id: idCloackSetting,
      push_group_id: 1,
      push_set_id: 1,
      reviews: !!commentsEdit[0].id ? commentsEdit.map(item => item.id) : [],
      facebook_pixels: metricsEdit.facebookPixelId.map(item => item.id),
      tiktok_pixels: metricsEdit.tikTokPixelId.map(item => item.id),
    }

    try {
      await sendAppPWA(itemToCreate);
    } catch (error) {
      console.error("Error from server:", error);
      throw new Error(`errot create From server`);
    }
  };

  const handleCreateClick = async () => {
    try {
      const idCloackSetting = await createCloackSetting();
      await createApp(idCloackSetting);

      toast.success('PWA создано!');

      navigate('/main/list');
    } catch (error) {
      console.error("error to create", error);
      toast.error('Ошибка создания PWA!');
    }
  };

  const handleEditClick = async () => {
    try {
      const itemToEdit = {
        ...itemsToFetch,
        cloack_settings_id: settingsEdit?.id,
        push_group_id: 1,
        push_set_id: 1,
        id: idPWA,
        reviews: commentsEdit.map(item => item.id),
        facebook_pixels: metricsEdit.facebookPixelId.map(item => item.id),
        tiktok_pixels: metricsEdit.tikTokPixelId.map(item => item.id),
      }

      await updateCloackSettings(settingsEdit, settingsEdit?.id || 0);
      await updateAppPWA(itemToEdit, idPWA || '0');

      toast.success('PWA отредактировано!');

      navigate('/main/list');
    } catch (error) {
      console.error("error to create", error);
      toast.error('Ошибка редактирования PWA!');
    }
  };

  return (
    <>
      <ButtonItem
        variant="blue"
        css={css}
        buttonText={pathName ? 'Создать' : 'Редактировать'}
        funcClick={pathName ? handleCreateClick : handleEditClick}
      />
    </>
  );
};

export default ButtonCreate;
