import { SubmitHandler, useForm } from "react-hook-form";
import {
  FormBasicStyled,
  ContainerCommentStyled,
  BlockStyled,
  RowStyled,
  ContentStyled,
  ButtonAddStyled,
  ButtonDeleteStyled,
  ButtonsSubmitStyled,
} from "./styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import PhotoInput from "../../../../../components/Inputs/PhotoInput/PhotoInput";
import { CommentType, ReviewEditType } from "../../../../../types/EditsTypes";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import { DatePickerControlInput } from "../../../../../components/Inputs/DatePickerInput/DatePikerInput";
import NumberInput from "../../../../../components/Inputs/NumberInput/NumberInput";
import TextAreaInput from "../../../../../components/Inputs/TextAreaInput/TextAreaInput";
import { RED_DELETE_SVG } from "../../../../../utils/SVG";
import { ButtonStyled } from "../../../../../helperStyled/styledHelper";
import { deleteAppReview, sendAppReview, updateAppReview } from "../../../../../api/requestService";
import { toast } from "react-toastify";

type Props = {
  itemEdit: CommentType;
  indexItem: number;
  setFieldsComments: React.Dispatch<React.SetStateAction<ReviewEditType>>;
  setEditIndex: React.Dispatch<React.SetStateAction<number | null>>;
};

const CommentFrom: React.FC<Props> = ({ itemEdit, indexItem, setFieldsComments, setEditIndex }) => {
  const { handleSubmit, control } = useForm<CommentType>({
    resolver: yupResolver(schema),
    defaultValues: itemEdit,
  });

  const onSubmit: SubmitHandler<CommentType> = async (data) => {
    try {
      if (!itemEdit.id) {
        const dataToFetch = {
          "avatar": data.avatar ? data.avatar[0] : null,
          "user_name": data.user_name,
          "publication_date": data.publication_date,
          "rating": Number(data.rating),
          "likes": Number(data.likes),
          "text": data.text,
          "reply_user_name": data.reply_user_name,
          "reply_date": data.reply_date,
          "reply_text": data.reply_text,
        }

        if (!data.reply_user_name || !data.reply_date || !data.reply_text) {
          delete dataToFetch.reply_user_name;
          delete dataToFetch.reply_date;
          delete dataToFetch.reply_text;
        };

        const newReview = await sendAppReview(dataToFetch);

        const reloadReview: CommentType = {
          "id": newReview.id ? newReview.id : 0,
          "avatar": newReview.avatar ? [newReview.avatar] : [],
          "user_name": newReview.user_name ? newReview.user_name : '',
          "publication_date": newReview.publication_date ? newReview.publication_date : '',
          "rating": newReview.rating ? newReview.rating.toString() : '',
          "likes": newReview.likes ? newReview.likes.toString() : '',
          "text": newReview.text ? newReview.text : '',
          "reply_user_name": newReview.reply_user_name ? newReview.reply_user_name : '',
          "reply_date": newReview.reply_date ? newReview.reply_date : '',
          "reply_text": newReview.reply_text ? newReview.reply_text : '',
        }

        setFieldsComments(prewValue => prewValue.map((item, index) => {
          return index !== indexItem ? item : reloadReview;
        }))
      } else {
        const dataToFetch = {
          id: itemEdit.id,
          "avatar": data.avatar ? data.avatar[0] : null,
          "user_name": data.user_name,
          "publication_date": data.publication_date,
          "rating": Number(data.rating),
          "likes": Number(data.likes),
          "text": data.text,
          "reply_user_name": data.reply_user_name,
          "reply_date": data.reply_date,
          "reply_text": data.reply_text,
        }

        if (!data.reply_user_name || !data.reply_date || !data.reply_text) {
          delete dataToFetch.reply_user_name;
          delete dataToFetch.reply_date;
          delete dataToFetch.reply_text;
        };

        const newReview = await updateAppReview(dataToFetch, itemEdit.id);

        const reloadReview: CommentType = {
          id: newReview.id ? newReview.id : 0,
          avatar: newReview.avatar ? [newReview.avatar] : [],
          user_name: newReview.user_name ? newReview.user_name : '',
          publication_date: newReview.publication_date ? newReview.publication_date : '',
          rating: newReview.rating ? newReview.rating.toString() : '',
          likes: newReview.likes ? newReview.likes.toString() : '',
          text: newReview.text ? newReview.text : '',
          reply_user_name: newReview.reply_user_name ? newReview.reply_user_name : '',
          reply_date: newReview.reply_date ? newReview.reply_date : '',
          reply_text: newReview.reply_text ? newReview.reply_text : '',
        }

        setFieldsComments(prewValue => prewValue.map((item, index) => {
          return index !== indexItem ? item : reloadReview;
        }));
      };

      setEditIndex(null);
    } catch (error) {
      toast.error('Ошибка сохранения комментария');
      console.error(error);
    }
  };

  const helperTextComment = (
    <>
      Доступные&nbsp;макросы:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      &#123;pwaName&#125;&nbsp;-&nbsp;название&nbsp;пва
      <br />
      &#123;developerName&#125;&nbsp;-&nbsp;имя&nbsp;разработчика
    </>
  );

  const handleDeleteComment = async () => {
    try {
      if (!itemEdit.id) {
        setFieldsComments(prewValue => prewValue.filter((item, index) => index !== indexItem));
      } else {
        await deleteAppReview(itemEdit.id);
  
        setFieldsComments(prewValue => prewValue.filter((item, index) => index !== indexItem));
      }
    } catch {
      console.log('error delete review');
    }
  };

  const handleCancelEdit = () => {
    setFieldsComments(prewValue => prewValue.filter((item) => item.id));
    setEditIndex(null);
  };

  return (
    <ContainerCommentStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <PhotoInput control={control} name={`avatar`} label='Avatar' isMulti={false} variant="right" />
        <RowStyled>

          <TextInput control={control} name={`user_name`} label="Автор" isExit={true} />
          <DatePickerControlInput
            control={control}
            placeholder="Введите значение"
            label="Дата"
            name={`publication_date`}
          />
        </RowStyled>

        <RowStyled>
          <NumberInput control={control} name={`rating`} label="Рейтинг" maxValue={5}/>
          <NumberInput control={control} name={`likes`} label="Лайки" />
        </RowStyled>

        <TextAreaInput
          control={control}
          name={`text`}
          label='Комментарий'
          helperText={helperTextComment}
          variantWidth='full'
        />

        <ButtonsSubmitStyled>
          <ButtonDeleteStyled onClick={handleDeleteComment}>
            <RED_DELETE_SVG />
          </ButtonDeleteStyled>

          <ButtonStyled
            variant="white"
            onClick={handleCancelEdit}
          >
            Отмена
          </ButtonStyled>

          <ButtonStyled
            variant="blue"
            type="submit"
          >
            Сохранить Комментарий
          </ButtonStyled>
        </ButtonsSubmitStyled>
      </BlockStyled>

      <BlockStyled>
        <TextInput control={control} name={`reply_user_name`} label="Автор ответа" isExit={true} />
        <DatePickerControlInput
          control={control}
          placeholder="Введите значение"
          label="Дата Ответа"
          name={`reply_date`}
        />
        <TextAreaInput
          control={control}
          name={`reply_text`}
          label='Комментарий ответа'
          variantWidth='small'
        />
      </BlockStyled>
    </ContainerCommentStyled>
  );
};

export default CommentFrom;
