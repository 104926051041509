import { SubmitHandler, useForm } from "react-hook-form";
import { NavigateOptionEdit } from "../../../../../types/NavigateOption";
import { PWAListType } from "../../../../../types/TypePWA";
import { FormAccardionsStyled, FormDescriptionStyled } from "./styled";
import ButtonsEdit from "../../ButtonsEdit/ButtonsEdit";
import BasicAcordion from "./BasicAcordion/BasicAcordion";
import ButtonAcordion from "./ButtonAcordion/ButtonAcordion";
import WhoNewAcordion from "./WhoNewAcordion/WhoNewAcordion";
import { setEditDescriptions } from "../../../../../store/aplicationsEdit/aplicationEdit";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/hooksStore";
import { DescriptionsEditType } from "../../../../../types/EditsTypes";
import { schema } from "./shema";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
};

const ContextDescription: React.FC<Props> = ({ setNavEdit }) => {

  const dispatch = useAppDispatch();

  const { descriptionsEdit } =
    useAppSelector((state) => state.aplicationEdit) || {};

  const { handleSubmit, control } = useForm<DescriptionsEditType>({
    resolver: yupResolver(schema),
    defaultValues: descriptionsEdit,
  });

  const onSubmit: SubmitHandler<DescriptionsEditType> = async (data) => {
    console.log(data);
    try {
      const dataContainer: DescriptionsEditType = {
        developer_contacts_id: data.developer_contacts_id,
        total_downloads: data.total_downloads,
        file_size: data.file_size,
        min_load_time: data.min_load_time,
        max_load_time: data.max_load_time,
        rating_id: data.rating_id,
        update_date: data.update_date,
        description: data.description,
        update_description: data.update_description,
        is_editors_choice: data.is_editors_choice,
        age_rating: data.age_rating,
        developer_name: data.developer_name,
        icon: data.icon,
        images: data.images,
        store_category_id: data.store_category_id,
        name: data.name,
        show_top_menu: data.show_top_menu,
        show_bottom_menu: data.show_bottom_menu,
        is_verified: data.is_verified,
      };

      dispatch(setEditDescriptions(dataContainer));

      setNavEdit("comments");
    } catch (error) {
      console.error("Error converting files to base64:", error);
    }
  };

  return (
    <FormDescriptionStyled onSubmit={handleSubmit(onSubmit)}>
      <FormAccardionsStyled>
        <BasicAcordion control={control} />

        <ButtonAcordion control={control} />

        <WhoNewAcordion control={control} />
      </FormAccardionsStyled>

      <ButtonsEdit setNavEdit={setNavEdit} toPage='basic' isActive={true}/>
    </FormDescriptionStyled>
  );
};

export default ContextDescription;
