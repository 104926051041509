import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./hooksStore";
import { getAppItem, getCloackSetting } from "../api/requestService";
import { BasicsEditType, DescriptionsEditType, InitialEditsType, SettingEditType } from "../types/EditsTypes";
import { AppsType } from "../types/TypePWA";
import { setId } from "../store/aplicationsEdit/aplicationEdit";
import { toast } from "react-toastify";

export const useEditPage = () => {
  const {
    developersAll = [],
    domainsAll = [],
    ratingsAll = [],
    trackersAll = [],
    category_idAll = [],
    tagsAll = [],
    storeCategoriesAll = [],
    pushSets = [],
  } = useAppSelector((state) => state.itemsSelect) || {};
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pushSetsToEdit = pushSets.map(item => ({id: item.id, name: item.name}));

  const getSetting = async (id: number) => {
    const cloackSetting: SettingEditType = await getCloackSetting(id);

    return cloackSetting;
  };

  const handleEditItem = async (idItem: string) => {
    try {
      const pwa: AppsType = await getAppItem(idItem);
      const itemToEditCloackSetting = await getSetting(pwa.cloack_settings_id);
      console.log(itemToEditCloackSetting);

      const itemToEditBasic: BasicsEditType = {
        internal_name: pwa.internal_name,
        domain_id:
          domainsAll.find((item) => item.id === pwa.domain_id)?.name || "",
        tracker_id:
          trackersAll.find((item) => item.id === pwa.tracker_id)?.name || "",
        category_id:
          category_idAll.find((item) => item.id === pwa.category_id)?.name || "",
        tags:
          tagsAll.filter((item) => pwa.tags.includes(item.id)) || [],
        push_set_id: pushSetsToEdit.find((item) => item.id === pwa.push_set_id) || {id: -1, name: ''},
      };

      const itemToEditDescription: DescriptionsEditType = {
        developer_contacts_id:
          developersAll.find((item) => item.id === pwa.developer_contacts_id)?.name ||
          "",
        total_downloads: String(pwa.total_downloads) || "",
        file_size: String(pwa.file_size) || "",
        min_load_time: String(pwa.min_load_time) || "",
        max_load_time: String(pwa.max_load_time) || "",
        rating_id:
          String(ratingsAll.find((item) => item.id === pwa.rating_id)?.total_rating) || "",
        update_date: pwa.update_date,
        description: pwa.description,
        update_description: pwa.update_description,
        is_editors_choice: pwa.is_editors_choice,
        age_rating: pwa.age_rating,
        developer_name: pwa.developer_name,
        icon: [pwa.icon],
        images: pwa.images,
        store_category_id: storeCategoriesAll.find((item) => item.id === pwa.store_category_id) || {id: -1, name: ''},
        name: pwa.name,
        show_top_menu: pwa.show_top_menu,
        show_bottom_menu: pwa.show_bottom_menu,
        is_verified: pwa.is_verified,
      };

      const itemToEditComments = pwa.reviews.map((item) => {
          return {
            id: item.id ? item.id : 0,
            "avatar": item ? [item.avatar] : [],
            "user_name": item ? item.user_name : '',
            "publication_date": item ? item.publication_date : '',
            "rating": item ? item.rating.toString() : '',
            "likes": item ? item.likes.toString() : '',
            "text": item ? item.text : '',
            "reply_user_name": item && item.reply_user_name ? item.reply_user_name : '',
            "reply_date": item && item.reply_date ? item.reply_date : '',
            "reply_text": item && item.reply_text ? item.reply_text : '',
          }
        });

      const itemToEdit: InitialEditsType = {
        id: pwa.id,
        basicsEdit: itemToEditBasic,
        descriptionsEdit: itemToEditDescription,
        settingsEdit: itemToEditCloackSetting,
        commentsEdit: itemToEditComments,
        metricsEdit: {
          facebookPixelId: pwa.facebook_pixels.map(item => {return {id: item.id || 0, name: item.pixel_id}}),
          tikTokPixelId: pwa.tiktok_pixels.map(item => {return {id: item.id || 0, name: item.pixel_id}}),
        }
      };

      dispatch(setId(itemToEdit));
      navigate("../edit");
    } catch {
      console.log("Error downloan item");
      toast.error('Ошибка загрузки PWA');
    }
  };

  return {handleEditItem};
};