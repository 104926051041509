import * as yup from 'yup';

export const schema = yup.object().shape({
  developer_contacts_id: yup
    .string()
    .required('Поле обязательное!'),
  total_downloads: yup
    .string()
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
  file_size: yup
    .string()
    .required('Поле обязательное!'),
  min_load_time: yup
    .string()
    .required('Поле обязательное!'),
  max_load_time: yup
    .string()
    .required('Поле обязательное!'),
  rating_id: yup
    .string()
    .required('Поле обязательное!'),
  update_date: yup
    .string()
    .required('Выберите дату!'),
  description: yup
    .string()
    .required('Поле обязательное!'),
  update_description: yup
    .string()
    .required('Поле обязательное!'),
  icon: yup
    .array()
    .min(1, 'Должно быть одно фото')
    .required('Поле обязательное!')
    .test('is-square', 'Неверное расширение', function (value) {
      if (!value || value.length === 0) return true;

      const image = value[0]; // Предполагаем, что загружено одно фото
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image; // image должна быть URL или base64

        img.onload = () => {
          const isSquare = img.width === img.height;
          resolve(isSquare);
        };

        img.onerror = () => resolve(false);
      });
    }),
  images: yup
    .array()
    .min(1, 'Должно быть минимум одно фото')
    .required('Поле обязательное!'),
  is_editors_choice: yup.boolean(),
  age_rating: yup.string().required('Поле обязательное!'),
  developer_name: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
  store_category_id: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }).test('not-default-value', 'Выберите значение!', value => {
    return value.id !== -1;
  }),
  name: yup.string().required('Поле обязательное!'),
  show_top_menu: yup.boolean().required('Поле обязательное!'),
  show_bottom_menu: yup.boolean().required('Поле обязательное!'),
  is_verified: yup.boolean().required('Поле обязательное!'),

  videoYouTube: yup.string().nullable(),
  texdDownloans: yup.string().nullable(),
  texdUseRedaction: yup.string().nullable(),
  old18: yup.string().nullable(),
  texdDescription: yup.string().nullable(),
  isButtonSpiner: yup.boolean().nullable(),
  texDownload: yup.string().nullable(),
});