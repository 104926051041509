import { CommentType } from "../../../../../types/EditsTypes";
import {
  ContainerCommentStyled,
  BlockStyled,
  FirstBlockStyled,
  AvatarStyled,
  NameAndDateStyled,
  CommentTextStyled,
} from "./styled";

type Props = {
  itemComment: CommentType;
  setEditIndex: React.Dispatch<React.SetStateAction<number | null>>;
  index: number;
};

const CommentSee: React.FC<Props> = ({ itemComment, setEditIndex, index }) => {
  return (
    <ContainerCommentStyled onClick={() => setEditIndex(index)}>
      <BlockStyled>
        <FirstBlockStyled>
          <AvatarStyled wid={20} hei={20} src={itemComment.avatar ? itemComment.avatar[0] : 'todo'}/>
          <NameAndDateStyled>
            <div>{itemComment.user_name}</div>
            <div>{itemComment.publication_date}</div>
          </NameAndDateStyled>
        </FirstBlockStyled>
        
        <CommentTextStyled>{itemComment.text}</CommentTextStyled>
      </BlockStyled>
    </ContainerCommentStyled>
  );
};

export default CommentSee;
